/* src/components/LogoSafakan.css */

.logo-container {
    display: grid;
    place-items: center; /* Center items horizontally and vertically */
    padding: 20px;
    margin-top: 5vh;
    margin: 0vh 0vw;
    /* height: 20vh; */
    border-radius: 15px;
  }
  
  .logo-image {
    width: 13%; /* Adjust the width as needed */
    min-width: 160px;
    max-width: 600px; /* Ensure the image doesn't get too large */
    height: auto; /* Maintain aspect ratio */
  }
  