.grid-container {
  display: flex;
  flex-direction: column;
  gap: 0px;
  padding: 20px;
  margin: 0vh 5vw; /* Adjusted for smaller screens */
  border: 2px solid black;
  height: 55vh; /* Increase height on smaller screens */
  border-radius: 15px;
  background-color: #000000;
}

.chat-area {
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px;
  height: 100%;
  max-height: 60vh; /* Increased max-height for more vertical space */
}

.message {
  padding: 10px; /* Adjust the padding as needed */
  border: 1px solid black; /* Adjust the border as needed */
  border-radius: 10px;
  width: fit-content;
  max-width: 80%;
  align-self: flex-start;
}

/* Media Queries for Responsive Design */
@media screen and (max-width: 1024px) {
  .grid-container {
    margin: 0vh 7vw; /* Slightly larger margins for tablets */
    height: 60vh; /* Increase height for tablets */
  }

  .chat-area {
    max-height: 68vh; /* Increase max-height for tablets */
  }
}

@media screen and (max-width: 768px) {
  .grid-container {
    margin: 0vh 5vw; /* Reduced margins for mobile devices */
    height: 60vh; /* Increase height for mobile devices */
  }

  .chat-area {
    max-height: 73vh; /* Increase max-height for mobile devices */
  }
}


.ai-message {
  background-color: rgb(51, 51, 51);
  color: rgb(189, 229, 230);
}

.admin-message {
  background-color: transparent;
  color: rgb(189, 229, 230);
  border: 0px;
}

.user-message {
  background-color: rgb(39, 84, 70);
  color: rgb(189, 229, 230);
  align-self: flex-end;
}


.input-area {
  background-color: rgb(51, 51, 51);
  border: 1px solid black;
  padding: 10px 20px;
  text-align: left;
  font-size: 1.5 rem;
  border-radius: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-height: 200px;
  height: 50px; 
  overflow: hidden;
}

.input-area textarea {
  width: 100%;
  height: auto;
  box-sizing: border-box;
  resize: none;
  font-size: 1rem;
  line-height: 1.5;
  padding: 5px 0;
  color: rgb(189, 229, 230);
}

.input-area-send-button {
  background-color: rgb(39, 84, 70);
  width: 81px;
  height: 31px;
  border: 0.9px solid black;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  cursor: pointer;
  margin-left: 10px;
  flex-shrink: 0;
}

