.grid-container-back {
  display: flex;
  flex-direction: column;
  gap: 0px;
  padding: 20px;
  margin: 0vh 5vw; /* Adjusted for smaller screens */
  border: 2px solid black;
  height: 55vh; /* Increase height on smaller screens */
  border-radius: 15px;
  background-color: #000000;
}

.about-me-area {
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  /* gap: 10px; */
  /* padding: 10px; */
  height: 100%;
  max-height: 53vh;
  color: rgb(189, 229, 230);
  /* justify-content: center; */
}
  


/* Media Queries for Responsive Design */
@media screen and (max-width: 1024px) {
  .grid-container-back {
    margin: 5vh 7vw; /* Slightly larger margins for tablets */
    height: 60vh; /* Increase height for tablets */
  }
}

@media screen and (max-width: 768px) {
  .grid-container-back {
    margin: 0vh 5vw; /* Reduced margins for mobile devices */
    height: 60vh; /* Increase height for mobile devices */
  }
}  